import { Plugin, computed, ref } from 'vue'
import {
  api,
  AudienceConfig,
  EXTENSION_AUTH_TOKEN,
  Insights,
  schema,
  StandaloneAudienceConfig,
  StandaloneAudienceConfigDetail,
  StandaloneNativePackGenerator,
} from './api'
import { useMixpanel } from './tracking'
import { messageService } from './app'
import { mapCountryToXCountry } from './pro-targeting/composables/utils'
import { formatBigNum } from './utils'
import settings from './settings'
import { JORAN_AD_ACCOUNT_ID } from './pro-targeting/composables/constants'
import { router } from './router'

const extensionDetected = ref<boolean>(false)
const isPopupWindow = ref<boolean>(false)
const adAccountAccessGranted = ref<boolean>()
const { track } = useMixpanel()

const adAccountIdExtension = ref<string>()
const adAccountNameExtension = ref<string>()
const adAccountHandleExtension = ref<string>()
const appliedJobTitles = ref(false)

export type TargetingPackage = { targeting_package: Insights['targeting_package'] } & {
  countries: Insights['countries']
}

export const extensionPlugin: Plugin = (app) => {
  // This Vue plugin connects with the SocialDatabase chrome extension

  window.addEventListener('message', (event) => {
    if (event.origin !== location.origin) return

    const message = event.data

    if (message?.type === 'sdExtensionState') {
      // content.js script from extension can be injected before JavaScript declares the variable `extensionDetected`
      try {
        extensionDetected.value = true
      } catch {}
      return
    }

    if (message?.type === 'sdAdAccountId') {
      if (adAccountIdExtension.value !== message.adAccountId && message.adAccountId) {
        adAccountIdExtension.value = message.adAccountId
        adAccountNameExtension.value = message.adAccountName
        adAccountHandleExtension.value = message.handle
      }
      return
    }
  })
}

function getTypeOfInsights(insights: Insights | StandaloneNativePackGenerator | TargetingPackage): string {
  if ('is_super_insights' in insights) {
    return 'targeting_list'
  }

  if ('created' in insights) {
    return 'builder'
  }

  if ('targeting_package' in insights) {
    return 'saved_list'
  }

  if (settings.MODE === 'development') {
    throw new Error('Unknown insights type')
  }

  return 'unknown_insighs_type'
}

export function useChromeExtension() {
  function canImportTargetingPackage(insights: TargetingPackage) {
    return !!(
      insights.targeting_package?.keywords?.filter((k) => !k.deselected)?.length ||
      insights.targeting_package?.handles?.filter((k) => !k.deselected)?.length ||
      insights.targeting_package?.hashtags?.filter((k) => !k.deselected)?.length ||
      insights.targeting_package?.languages?.length ||
      insights.targeting_package?.network_operators?.length ||
      insights.targeting_package?.interests?.filter((k) => !k.deselected)?.length ||
      insights.targeting_package?.conversation_topics?.filter((k) => !k.deselected)?.length ||
      insights.targeting_package?.movies_tv_shows?.filter((k) => !k.deselected)?.length ||
      insights.targeting_package?.placements?.length ||
      insights.targeting_package?.age ||
      insights.targeting_package?.gender ||
      insights.targeting_package?.custom_audiences?.length ||
      insights.targeting_package?.locations?.length ||
      insights.countries?.length
    )
  }

  async function fillTargetingPackage(
    insights: Insights | StandaloneNativePackGenerator | TargetingPackage,
    audienceConfig?: AudienceConfig | StandaloneAudienceConfig | StandaloneAudienceConfigDetail,
    adAccountIdParam?: string,
  ) {
    const adAccountId = adAccountIdParam ? adAccountIdParam : adAccountIdExtension.value
    track('Extension Audience Copied', {
      name: audienceConfig?.name,
      ad_account_id: adAccountId,
      type: getTypeOfInsights(insights),
    })

    if (!extensionDetected.value) {
      alert(
        'The X Pro Targeting extension is not installed. Please install the extension on Chrome to use this feature. A new page will now open to where you can download the extension.',
      )

      const href = router.resolve({ name: 'pro-targeting-integration' }).href
      if (href) {
        window.open(href, '_blank')
      } else {
        window.open(
          'https://chromewebstore.google.com/detail/%F0%9D%95%8F-pro-targeting/onkfhapdikdljooblkaigahglmnpaplk',
          '_blank',
        )
      }

      throw new Error('Extension not detected')
    }

    if (!canImportTargetingPackage(insights)) {
      messageService.error('Failed to apply targeting list because it is empty.')
      throw new Error('No targeting package')
    }

    const keywords = insights.targeting_package?.keywords?.filter((k) => !k.deselected)?.map((x) => x.label) ?? []
    const handles =
      insights.targeting_package?.handles
        ?.filter((k) => !k.deselected)
        ?.map((x) => x.handle)
        ?.slice(0, 100) ?? []
    if (
      insights.targeting_package?.handles &&
      insights.targeting_package.handles.filter((k) => !k.deselected).length > 100
    ) {
      alert('More than 100 handles are selected. Only the first 100 selected handles will be applied.')
    }
    const hashtags = insights.targeting_package?.hashtags?.filter((k) => !k.deselected)?.map((x) => x.label) ?? []

    let locations = insights.targeting_package?.locations ?? []
    if (typeof locations === 'object') {
      locations = Object.values(locations)
    }

    // also apply age & gender
    const countries = (insights.countries?.map((x) => x.label) ?? []).map(mapCountryToXCountry)

    const languages = insights.targeting_package?.languages?.map((x) => x.label) ?? []
    const networkOperators = insights.targeting_package?.network_operators?.map((x) => x.label) ?? []
    const interests =
      insights.targeting_package?.interests?.filter((k) => !k.deselected)?.map((x) => x.label.replace('/', ' — ')) ?? []
    const conversationTopics =
      insights.targeting_package?.conversation_topics?.filter((k) => !k.deselected)?.map((x) => x.label) ?? []
    const moviesTvShows =
      insights.targeting_package?.movies_tv_shows?.filter((k) => !k.deselected)?.map((x) => x.label) ?? []

    // insights.targeting_package.customsAudiences or placements can be a ref, so we need to unref it
    const customAudiences = insights.targeting_package?.custom_audiences?.map((x) => x) ?? []
    const placements = insights.targeting_package?.placements?.map((x) => x) ?? []
    const gender = insights.targeting_package?.gender
    const age = insights.targeting_package?.age

    const audienceType = audienceConfig?.categories?.some((c) => c.name === 'Exclusion Audience')
      ? 'exclusion'
      : 'targeting'

    if (adAccountAccessGranted.value === undefined) {
      await getAdAccount(adAccountId)
    }
    try {
      if (adAccountAccessGranted.value && adAccountId && customAudiences.length) {
        const customAudiencesStatuses = await api.call(
          schema.StandaloneCheckCustomAudiences.create,
          {
            body: {
              ad_account_id: adAccountId,
              custom_audience_names: customAudiences,
            },
          },
          {
            headers: {
              Authorization: `Token ${EXTENSION_AUTH_TOKEN}`,
            },
          },
        )
        if (customAudiencesStatuses.custom_audiences.some((s) => !s.targetable)) {
          const response = confirm(
            'Some custom audiences are not ready and will not be applied. This may take up to 48 hours to be processed by X. Do you still want to apply the rest of the targeting package?',
          )
          if (!response) {
            return
          }
        }
      }
    } catch {}

    const targetingFile =
      false && audienceConfig && adAccountId === JORAN_AD_ACCOUNT_ID
        ? (
            await api.call(
              schema.StandaloneAudienceConfig.download_targeting_package,
              { path: { pk: 'audienceConfig.id' } },
              {
                headers: { Authorization: `Token ${EXTENSION_AUTH_TOKEN}` },
              },
            )
          ).url
        : undefined

    const message = {
      type: 'sdFillTargetingPackage',
      adAccountId,
      audienceType,
      keywords,
      handles,
      hashtags,
      countries: countries.length < 50 ? countries : [],
      locations,
      languages,
      networkOperators,
      interests,
      conversationTopics,
      moviesTvShows,
      customAudiences,
      placements,
      age,
      gender,
      targetingFile,
      adAccountAccessGranted: adAccountAccessGranted.value,
      audienceConfigSize: audienceConfig && 'size' in audienceConfig ? formatBigNum(audienceConfig.size) : undefined,
      audienceConfigName: audienceConfig?.name,
      audienceConfigId: audienceConfig?.id,
    }
    window.postMessage(message, location.origin)

    if (audienceConfig && adAccountId) {
      await api.call(
        schema.StandaloneAudienceUsedInAds.create,
        {
          body: {
            ad_account_id: adAccountId,
            state: 'applied',
            audience_config_id: audienceConfig?.id,
            targeting_package: insights.targeting_package,
            countries: insights.countries,
          },
        },
        { headers: { Authorization: `Token ${EXTENSION_AUTH_TOKEN}` } },
      )
    }
  }

  async function getAdAccount(adAccountId?: string, refetch = false) {
    if (!refetch && adAccountAccessGranted.value !== undefined) {
      return adAccountAccessGranted.value
    }

    const accountId = adAccountId || adAccountIdExtension.value

    if (!accountId) {
      return false
    }

    const response = await api.call(
      schema.StandaloneAudienceRequest.validate_ad_account,
      { body: { ad_account_id: accountId } },
      { headers: { Authorization: `Token ${EXTENSION_AUTH_TOKEN}` } },
    )
    adAccountAccessGranted.value = response.access
    return response
  }

  async function createAdAccount(adAccountId?: string, name?: string) {
    const accountId = adAccountId || adAccountIdExtension.value
    const n = name || adAccountNameExtension.value
    if (!accountId) throw new Error('No ad account id provided')

    const response = await api.call(
      schema.StandaloneAdAccount.create,
      {
        body: {
          account_id: accountId,
          name: n,
        },
      },
      { headers: { Authorization: `Token ${EXTENSION_AUTH_TOKEN}` } },
    )
    adAccountAccessGranted.value = true
    return response
  }

  async function createAudienceAdAccount(adAccountId?: string, name?: string) {
    const accountId = adAccountId || adAccountIdExtension.value
    const n = name || adAccountNameExtension.value
    if (!accountId) throw new Error('No ad account id provided')

    const response = await api.call(
      schema.StandaloneAdAccount.create_audiences_ad_account,
      {
        body: {
          account_id: accountId,
          name: n,
        },
      },
      { headers: { Authorization: `Token ${EXTENSION_AUTH_TOKEN}` } },
    )
    return response
  }

  const isInExtension = computed(() => isPopupWindow.value && extensionDetected.value)

  return {
    extensionDetected,
    isPopupWindow,
    isInExtension,
    adAccountId: adAccountIdExtension,
    adAccountName: adAccountNameExtension,
    adAccountHandle: adAccountHandleExtension,
    adAccountAccessGranted,
    appliedJobTitles,
    canImportTargetingPackage,
    fillTargetingPackage,
    getAdAccount,
    createAdAccount,
    createAudienceAdAccount,
  }
}
